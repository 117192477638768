const messagesMap = new Map ([

    //사용자
    ["MODULE_USER_ERROR_00001", "사용자 정보가 올바르지 않습니다."],
    ["MODULE_USER_ERROR_00002", "사용자 데이터가 이미 존재합니다."],
    ["MODULE_USER_ERROR_00003", "사용자 정보가 올바르지 않습니다."],
    ["MODULE_USER_ERROR_00004", "로그인이 되어있지 않습니다."],
    ["MODULE_USER_ERROR_00005", "사용자 ID가 올바르지 않습니다."],
    ["MODULE_USER_ERROR_00006", "사용자 로그인 유형이 존재하지 않습니다."],
    ["MODULE_USER_ERROR_00007", "비활성화 되어있는 사용자입니다."],
    ["MODULE_USER_ERROR_00008", "기존 비밀번호와 일치하지 않습니다."],

    //승인(결재)
    ["MODULE_APPROVAL_ERROR_00001", "승인 정보를 찾을 수 없습니다."],
    ["MODULE_APPROVAL_ERROR_00002", "승인 데이터가 이미 존재합니다."],

    //회사
    ["MODULE_COMPANY_ERROR_00001", "승인 정보를 찾을 수 없습니다."],
    ["MODULE_COMPANY_ERROR_00002", "승인 데이터가 이미 존재합니다."],

    //공휴일
    ["MODULE_HOLIDAY_ERROR_00001", "공휴일 정보를 찾을 수 없습니다."],
    ["MODULE_HOLIDAY_ERROR_00002", "공휴일 데이터가 이미 존재합니다."],

    //직위
    ["MODULE_GRADE_ERROR_00001", "직위를 찾을 수 없습니다."],
    ["MODULE_GRADE_ERROR_00002", "직위 데이터가 이미 존재합니다."],

    //직책
    ["MODULE_DUTY_ERROR_00001", "직책를 찾을 수 없습니다."],
    ["MODULE_DUTY_ERROR_00002", "직책 데이터가 이미 존재합니다."],

    //부서
    ["MODULE_DEPARTMENT_ERROR_00001", "부서 정보를 찾을 수 없습니다."],
    ["MODULE_DEPARTMENT_ERROR_00002", "부서 데이터가 이미 존재합니다."],

    //메뉴
    ["MODULE_MENU_ERROR_00001", "메뉴 정보를 찾을 수 없습니다."],
    ["MODULE_MENU_ERROR_00002", "메뉴 데이터가 이미 존재합니다."],

    //프로젝트 구분
    ["MODULE_PROJECT_KIND_ERROR_00001", "프로젝트 구분 정보를 찾을 수 없습니다."],
    ["MODULE_PROJECT_KIND_ERROR_00002", "프로젝트 구분 데이터가 이미 존재합니다."],
    ["MODULE_PROJECT_KIND_ERROR_00003", "해당 프로젝트 구분으로 설정되어있는 프로젝트가 존재합니다."],

    //프로젝트
    ["MODULE_PROJECT_TYPE_ERROR_00001", "프로젝트 정보를 찾을 수 없습니다."],
    ["MODULE_PROJECT_TYPE_ERROR_00002", "프로젝트 데이터가 이미 존재합니다."],
    ["MODULE_PROJECT_TYPE_ERROR_00003", "해당 프로젝트로 설정되어있는 세부내역이 존재합니다."],

    //프로젝트 세부내역
    ["MODULE_PROJECT_ERROR_00001", "프로젝트 정보를 찾을 수 없습니다."],
    ["MODULE_PROJECT_ERROR_00002", "프로젝트 데이터가 이미 존재합니다."],
    ["MODULE_PROJECT_ERROR_00003", "해당 프로젝트 세부내역으로 설정되어있는 실적건이 존재합니다."],

    //설정정보
    ["MODULE_COMMON_CONFIG_ERROR_00001", "설정 정보를 찾을 수 없습니다."],
    ["MODULE_COMMON_CONFIG_ERROR_00002", "설정 데이터가 이미 존재합니다."],

    //실적
    ["MODULE_PERFORMANCE_ERROR_00001", "실적입력 정보를 찾을 수 없습니다."],
    ["MODULE_PERFORMANCE_ERROR_00002", "실적 데이터가 이미 존재합니다."],
    ["MODULE_PERFORMANCE_ERROR_00003", "실적입력 마감 정보를 찾을 수 없습니다."],
    ["MODULE_PERFORMANCE_ERROR_00004", "실적입력이 마감되었습니다."],
    ["MODULE_PERFORMANCE_ERROR_00005", "1일별 입력가능한 실적시간을 초과하였습니다."],
    ["MODULE_PERFORMANCE_ERROR_00006", "주말 또는 공휴일은 실적입력이 불가능합니다."],
    ["MODULE_PERFORMANCE_ERROR_00007", "현재 승인진행중인 실적건으로 삭제가 불가능합니다."],

    //공통
    ["MODULE_COMMON_ERROR_00001", "처리 중 오류가 발생했습니다."],
    ["MODULE_COMMON_ERROR_00002", "입력 파라미터가 올바르지 않습니다."],
    ["MODULE_COMMON_ERROR_00003", "파일 업로드 용량은 최대 10MB를 초과할 수 없습니다."],
    ["MODULE_COMMON_ERROR_00004", "접근이 거부되었습니다."],
    ["MODULE_COMMON_ERROR_00005", "토큰이 만료되었습니다."],
    ["MODULE_COMMON_ERROR_00006", "토큰이 유효하지 않습니다."],
    ["MODULE_COMMON_ERROR_00007", "데이터를 찾을 수 없습니다."],
    ["MODULE_COMMON_ERROR_00008", "이미 존재하는 데이터입니다."],
    ["MODULE_COMMON_ERROR_00009", "Operation not allowed."],
    ["MODULE_COMMON_ERROR_00010", "Unable to perform"],
    ["MODULE_COMMON_ERROR_00011", "이 기능은 현재 지원되지않는 기능입니다."],
    ["MODULE_COMMON_ERROR_00012", "Invalid Encryption Parameter."],
    ["MODULE_COMMON_ERROR_00013", "Invalid Decryption Parameter."],
    ["MODULE_COMMON_ERROR_00014", "요청 헤더를 찾을 수 없습니다."],
    ["MODULE_COMMON_ERROR_00015", "요청 본문을 찾을 수 없습니다."],
    ["MODULE_COMMON_ERROR_00016", "잘못된 요청입니다."],
    ["MODULE_COMMON_ERROR_00017", "IO 오류가 발생했습니다."],
    ["MODULE_COMMON_ERROR_00018", "Cache 서버 오류가 발생하였습니다."],
    ["MODULE_COMMON_ERROR_00019", "RefreshToken 정보를 찾을 수 없습니다."]
]);

export default (code) => {
    return messagesMap.get(code);
};