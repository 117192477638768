import { createApp } from "vue";
import Vuex from 'vuex'
import App from "./App.vue";
import * as EvaIcons from "@stefandesu/eva-icons-vue"
import router from "./router";
import BootstrapVueNext from 'bootstrap-vue-next'
import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3"
import VueCookies from 'vue3-cookies'
import Vue3Toastify, { toast } from 'vue3-toastify';
import Vuelidate from '@vuelidate/core'
import flatPickr from 'vue-flatpickr-component';
import { vMaska } from "maska"
import moment from 'moment'
import momentBusinessDays from 'moment-business-days'
import utils from "@utils";
import store from './store'
import { FontAwesomeIcon } from './plugins/font-awesome'
import apiInterceptors from './services/api.interceptors';
import { Tabs, Tab } from 'vue3-tabs-component';
import { BModal } from "bootstrap-vue-next";

import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net-dt';

import 'datatables.net-fixedheader-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-rowgroup-dt';
import 'datatables.net-select-dt';

import 'sweetalert2/dist/sweetalert2.min.css';
import '@vueform/slider/themes/default.css';
import './assets/scss/custom/plugins/mermaid.min.css'
import '/node_modules/bootstrap/dist/css/bootstrap.css'
import '/node_modules/bootstrap-vue-next/dist/bootstrap-vue-next.css'
import '/node_modules/vue3-toastify/dist/index.css';
import '/node_modules/flatpickr/dist/flatpickr.css';
import './assets/scss/app.scss';
import '/node_modules/ag-grid-community/styles/ag-grid.css'; // Core CSS
import '/node_modules/ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import '/node_modules/datatables.net-dt/css/dataTables.dataTables.min.css';

DataTable.use(DataTablesLib);

apiInterceptors(store);

const offset = (new Date().getTimezoneOffset());
const today = new Date(((new Date()).getTime() - (offset * 60 * 1000)))
  .toISOString()
  .split('T')[0];

var app = createApp(App);
app.use(EvaIcons);
app.use(router);
app.use(BootstrapVueNext);
app.use(BootstrapVue3);
app.use(vClickOutside);
app.use(VueCookies);
app.use(Vuex);
app.use(store);
app.use(Vuelidate);
app.use(Vue3Toastify,
    {
        autoClose: 2000,
        toastId: "custom-toast-id",
        position: toast.POSITION.TOP_CENTER,
        transition: toast.TRANSITIONS.SLIDE,
        toastStyle: {
            fontSize: '15px',
            fontFamily: 'NanumSquare',
            width: '550px'
        },
        limit: 1,
        multiple: false
    }
);




//moment
moment.locale('ko');

app.config.globalProperties.$utils = utils;
//app.config.globalProperties.$cookies = VueCookies;
app.config.globalProperties.$toast = toast;
app.config.globalProperties.$moment = moment;

app.component("font-awesome-icon", FontAwesomeIcon);
app.component('b-modal', BModal)
app.component('tabs', Tabs)
app.component('tab', Tab)
app.component('flat-pickr', flatPickr)
app.component('data-table', DataTable);

app.provide('today', today);
app.provide('toast', toast);

app.directive("maska", vMaska);

app.mount("#app");