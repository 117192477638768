<template>
    <div>
        <loading
            :active="loading"
            loader="dots"
            :width="84"
            :height="84"
            :opacity="0.5"
            :can-cancel="false" 
            :is-full-page="true"
            color="#002d72"
            background-color="#eeeeee">
        </loading>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import '/node_modules/vue-loading-overlay/dist/css/index.css';

export default {
    props: {
        loading: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            loadingOptions: {
                width: 84,
                height: 84
            }
        } 
    },
    components: {
        Loading
    }
}
</script>