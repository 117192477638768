import api from './api'
import TokenService from './token.service';
import DeviceService from "./device.service"

class AuthService {
    
    login(user) {
        // deviceInfo 추가
        user.deviceInfo = {
            deviceId: DeviceService.getDeviceUUID(),
            deviceType: "DEVICE_TYPE_PC",
            //notificationToken: "notificationToken"
        }
    
        return api.post("/v1/login", user).then((res) => {
            if (res.data.data.auth.accessToken) {
                TokenService.setUser(res.data.data);
            }
    
            return res.data.data;
        });
    }

    logout() {
        const user = {
            accessToken: TokenService.getLocalAccessToken(),
            deviceInfo: {
                deviceId: DeviceService.getDeviceUUID(),
                deviceType: "DEVICE_TYPE_PC",
                //notificationToken: "notificationToken"
            }
        };

        return api.post("/v1/logout", user)
            .then((res) => {
                TokenService.removeUser();

                /*
                if(res.data.success) {
                    TokenService.removeUser();
                }
                return res.data.data;
                */
            })
            .catch((err) => {
                TokenService.removeUser();
            });
    }
}

export default new AuthService();