import { createWebHistory, createRouter } from "vue-router";
import { useCookies } from "vue3-cookies";
import store from '../store';
import TokenService from '@/services/token.service';

const hasRoleTeamMember = () => (to, from, next) => {
  if(TokenService.getUser().isTeamMember) {
    return next();
  }

  next('/error/401');
}

const hasRoleTeamLeader = () => (to, from, next) => {
  if(TokenService.getUser().isTeamLeader) {
    return next();
  }

  next('/error/401');
}

const hasRoleSystemAdmin = () => (to, from, next) => {
  if(TokenService.getUser().isSystemAdmin) {
    return next();
  }

  next('/error/401');
}

const hasRoleSuperAdmin= () => (to, from, next) => {
  if(TokenService.getUser().isSuperAdmin) {
    return next();
  }

  next('/error/401');
}

const hasRolePerformanceReportViewer= () => (to, from, next) => {
  if(TokenService.getUser().isPerformanceReportViewer) {
    return next();
  }

  next('/error/401');
}

const routes = [

  //root
  {
    path: '/',
    name: 'root',
    redirect: '/auth/login'
  },


  
  //auth
  {
    path: '/auth/login',
    name: 'login',
    component: () => import('../views/pages/auth/login.vue')
  },
  {
    path: '/auth/reset-password',
    name: 'reset-password',
    component: () => import('../views/pages/auth/reset-password.vue')
  },



  //실적입력
  {
    path: '/performance/calendar',
    name: 'performance-calendar',
    component: () => import('../views/pages/performance/calendar/index.vue'),
    beforeEnter: hasRoleTeamMember()
  },


   //승인요청
   {
    path: '/performance/approve',
    name: 'performance-approve',
    component: () => import('../views/pages/performance/approve/index.vue'),
    beforeEnter: hasRoleTeamMember()
  },


   //실적 미입력자 확인
   {
    path: '/performance/not-entered',
    name: 'performance-not-entered',
    component: () => import('../views/pages/performance/not-entered/index.vue'),
    beforeEnter: hasRoleTeamMember()
  },


   //승인처리
   {
    path: '/performance/confirm',
    name: 'performance-confirm',
    component: () => import('../views/pages/performance/confirm/index.vue'),
    beforeEnter: hasRoleTeamMember()
  },


   //실적확인
   /*
   {
    path: '/performance/review',
    name: 'performance-review',
    component: () => import('../views/pages/performance/review/index.vue'),
    beforeEnter: hasRoleTeamMember()
  },
  */

  //실적확인
  {
    path: '/performance/review_v2',
    name: 'performance-review_v2',
    component: () => import('../views/pages/performance/review_v2/index.vue'),
    beforeEnter: hasRoleTeamMember()
  },

  //프로젝트 즐겨찾기
  {
    path: '/project/bookmark',
    name: 'project-bookmark',
    component: () => import('../views/pages/project/bookmark/index.vue'),
    beforeEnter: hasRoleTeamMember()
  },

  //실적관리 (부서장)
  {
    path: '/performance/manage-leader',
    name: 'performance-manage-leader',
    component: () => import('../views/pages/performance/manage-leader/index.vue'),
    beforeEnter: hasRoleTeamLeader()
  },


  //승인모니터링
  {
    path: '/manage/performance-monitoring',
    name: 'manage-performance-monitoring',
    component: () => import('../views/pages/manage/performance-monitoring/index.vue'),
    beforeEnter: hasRoleTeamLeader()
  },


  //실적현황
  {
    path: '/manage/performance-stats',
    name: 'manage-performance-stats',
    component: () => import('../views/pages/manage/performance-stats/index.vue'),
    beforeEnter: hasRoleTeamLeader()
  },

    //실적보고 (부서장)
    {
      path: '/performance/report/dept-leader',
      name: 'performance-report-dept-leader',
      component: () => import('../views/pages/performance/report/dept-leader/index.vue'),
      beforeEnter: hasRoleTeamLeader()
    },

  //권한관리 - 그룹설정
  {
    path: '/manage/group',
    name: 'manage-group',
    component: () => import('../views/pages/manage/group/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },

  //권한관리 - 그룹 매칭
  {
    path: '/manage/role-group',
    name: 'manage-role-group',
    component: () => import('../views/pages/manage/role-group/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },

  //권한관리 - 실적보고설정
  {
    path: '/manage/role-report-group',
    name: 'manage-role-report-group',
    component: () => import('../views/pages/manage/role-report-group/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },


   //실적관리
   {
    path: '/manage/performance',
    name: 'manage-performance',
    component: () => import('../views/pages/manage/performance/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },

  //실적보고 - 프로젝트 (권한부여자)
  {
    path: '/performance/report/project-role',
    name: 'performance-report-project-role',
    component: () => import('../views/pages/performance/report/project-role/index.vue'),
    beforeEnter: hasRolePerformanceReportViewer()
  },

  //실적보고 - 프로젝트
  {
    path: '/performance/report/project',
    name: 'performance-report-project',
    component: () => import('../views/pages/performance/report/project/index.vue'),
    beforeEnter: () => { hasRoleSystemAdmin() }
  },

  //실적보고 - 본부
  {
    path: '/performance/report/headquarter',
    name: 'performance-report-headquarter',
    component: () => import('../views/pages/performance/report/headquarter/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },

  //회사 관리
  {
    path: '/manage/company',
    name: 'manage-company',
    component: () => import('../views/pages/manage/company/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },


  //부서 관리
  {
    path: '/manage/department',
    name: 'manage-department',
    component: () => import('../views/pages/manage/department/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },


  //공휴일 관리
  {
    path: '/manage/holiday',
    name: 'manage-holiday',
    component: () => import('../views/pages/manage/holiday/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },



  //프로젝트 관리
  {
    path: '/manage/project_v2',
    name: 'manage-project_v2',
    component: () => import('../views/pages/manage/project_v2/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },

  /*
  {
    path: '/manage/project',
    name: 'manage-project',
    component: () => import('../views/pages/manage/project/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },

  {
    path: '/manage/project-type',
    name: 'manage-project-type',
    component: () => import('../views/pages/manage/project-type/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },

  {
    path: '/manage/project-kind',
    name: 'manage-project-kind',
    component: () => import('../views/pages/manage/project-kind/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },
  */

  //직위 관리
  {
    path: '/manage/grade',
    name: 'manage-grade',
    component: () => import('../views/pages/manage/grade/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },


  //직책 관리
  /*
  {
    path: '/manage/duty',
    name: 'manage-duty',
    component: () => import('../views/pages/manage/duty/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },
  */


  //사용자 관리
  {
    path: '/manage/user',
    name: 'manage-user',
    component: () => import('../views/pages/manage/user/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },


  //실적입력 마감관리
  {
    path: '/manage/performance-close',
    name: 'manage-performance-close',
    component: () => import('../views/pages/manage/performance-close/index.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },


  //etc
  {
    path: '/pages/etc/starter',
    name: 'pages-starter',
    component: () => import('../views/pages/etc/starter.vue'),
    beforeEnter: hasRoleSystemAdmin()
  },



  //faq
  {
    path: '/pages/faq/faqs',
    name: 'pages-faqs',
    component: () => import('../views/pages/faq/faqs.vue'),
    beforeEnter: hasRoleTeamMember()
  },



  //error
  {
    path: '/error/401',
    name: 'error-401',
    component: () => import('../views/pages/error/401.vue'),
  },
  {
    path: '/error/404',
    name: 'error-404',
    component: () => import('../views/pages/error/404.vue')
  },
  {
    path: '/error/500',
    name: 'error-500',
    component: () => import('../views/pages/error/500.vue')
  },
  

  //default error page handling
  {
    path: '/:pathMatch(.*)*',
    name: 'notfound',
    redirect: "/error/404"
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  // mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

/**
 * to: 이동할 url 정보가 담긴 라우터 객체
 * from: 현재 url 정보가 담긴 라우터 객체
 * next: to에서 지정한 url로 이동하기 위해 꼭 호출해야 하는 함수
 * next() 가 호출되기 전까지 화면 전환되지 않음
 */
//네비게이션 가드((뷰 라우터로 URL 접근에 대해서 처리할 수 있음)
router.beforeEach((to, from, next) => { //여기서 모든 라우팅이 대기 상태가 됨
  store.commit('startLoading');

  const publicPages = ['/auth/login', '/auth/reset-password'];
  const authRequired = !publicPages.includes(to.path);
  if (authRequired && !store.state.auth.status.loggedIn) {
    next('/auth/login');
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  store.commit('endLoading');
})

export default router;
