import utils from "@/utils"
import { toast } from 'vue3-toastify';
import router from "@/router";
import axiosInstance from "./api"
import TokenService from "./token.service"

const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            //store.commit('startLoading');

            if(config.url !== '/v1/login') {
                const token = TokenService.getLocalAccessToken();
                //console.log("axiosInstance.interceptors.request >\n" + "URL: " + config.url);

                if (token) {
                    config.headers["Authorization"] = 'Bearer ' + token; // Spring Boot
                    //config.headers["x-access-token"] = token;   // Node.js Express back-end
                }
            }
            return config;
        },
        (error) => {
            //store.commit('endLoading');
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            //store.commit('endLoading');
            return res;
        },
        async (err) => {
            //store.commit('endLoading');

            let originalConfig = err.config;

            //if(originalConfig.url !== '/v1/login' && originalConfig.url !== '/v1/reissue' && err.response) {
            if(originalConfig.url !== '/v1/login' && originalConfig.url !== '/v1/reissue' && err.response) {
                //console.log("axiosInstance.interceptors.response >\nERR STATUS: " + err.response.status)
                // Access Token이 만료됨
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    try {
                        const rs = await axiosInstance.post("/v1/reissue", {
                            refreshToken: TokenService.getLocalRefreshToken()
                        })

                        const { accessToken } = rs.data.data;

                        store.dispatch('auth/refreshToken', accessToken);
                        TokenService.updateLocalAccessToken(accessToken);
                        originalConfig.headers['Authorization'] = 'Bearer ' + accessToken;
            
                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        //originalConfig._retry = true;

                        if(_error.config.url === '/v1/reissue' && _error.response.status === 401 && originalConfig._retry) {
                            //RefreshToken 토큰 재발급을 1번 이상 시도 중, Redis 토큰이 만료 및 삭제되어 더이상 재발급이
                            //불가한 경우 저장된 정보를 삭제하고 강제 로그인 페이지로 이동
                            TokenService.removeUser();
                            store.dispatch('auth/logoutForce'); 
                            router.push({ name: "login", query: { action: "expired" } });
                        } else {
                            if (_error.response && _error.response.data) {
                                return Promise.reject(_error.response.data);
                            }
                        }

                        return Promise.reject(_error);
                    }
                }

                if(err.response.status == 500) {
                    console.log("An error has been occured.", err);
                    if(err.response.data.indexOf("ECONNREFUSED")) {
                        toast.error("처리 중 오류가 발생하였습니다.");
                    } else {
                        toast.error("처리 중 오류가 발생하였습니다.");
                    }
                } else {
                    printToastMessage(err);
                }

                /*
                if (err.response.status === 403 && err.response.data) {
                    return Promise.reject(err.response.data);
                }
                */
            } else {
                printToastMessage(err);
            }

            return Promise.reject(err);
        }
    );
};

function printToastMessage(err) {
    //예외처리 페이지에 대하여 백엔드 응답 메시지 출력 처리
    if(err.response.data.code.indexOf("MODULE") > -1) {
        if(err.response.data.code == "MODULE_COMMON_ERROR_00002") {
            toast.error(err.response.data.validation[0]);
        } else {
            toast.error(utils.messages(err.response.data.code));
        }
    } else {
        toast.error("처리 중 오류가 발생하였습니다.");
        console.log("An error has been occured.", err);
    }
}

export default setup;


/*
service.interceptors.request.use(
    (config) => {
        store.commit('startLoading');
        
        let accessToken = cookies.get('accessToken') ? cookies.get('accessToken') : null;
        let refreshToken = cookies.get('refreshToken') ? cookies.get('refreshToken') : null;
        let requestUrl = config.url;

        if(requestUrl != "/login" && requestUrl != "/logout" && requestUrl != "/reissue") {
            if(accessToken == null || refreshToken == null) {
                //쿠키 유효기간 만료로 accessToken 또는 refreshToken이 없는 경우 로그인 페이지로 이동
                router.push({ name: "login", query: { action: "expired" } });
            } else {
                let decoded = jwtDecode(accessToken);
                let now = new Date();
                let expiry = decoded.exp - Number(now.getTime().toString().substr(0, 10));

                if(expiry < 600) {
                    //토큰 유효시간이 10분이하로 남은 경우 토큰 재발급 처리
                    store.dispatch('reissue', { refreshToken: cookies.get('refreshToken') }).then((res) => {
                        //refreshToken 기간이 만료되면 로그인 페이지 이동
                        if(res.response.status == 401) {
                            router.push({ name: "login", query: { action: 'expired' } });
                        }
                    });
                }
            }
        }
        
        config.headers['Authorization'] = `Bearer ${accessToken != null ? accessToken : ""}`;

        return config;
    },
    (error) => {
        store.commit('endLoading');
        return error;
    }
)

service.interceptors.response.use(
    (res) => {
        store.commit('endLoading');
        return res;
    },
    (error) => { 
        store.commit('endLoading');
        return Promise.reject(error);
    }
)

const handleError = (error) => {
    //console.log(`axios handleError (${error.config.method})`, error);

    if(error.response.status == 401) {
        router.push({ name: "login", query: { action: 'expired' } });
        store.dispatch('reissue', { refreshToken: cookies.get('refreshToken') }).then((res) => {
            //refreshToken 기간이 만료되면 로그인 페이지 이동
            if(res.response?.status == 401) {
                router.push({ name: "login", query: { action: 'expired' } });
            }
        });
    } else if(error.response.status == 500) {
        console.log("An error has been occured.", error);
        if(error.response.data.indexOf("ECONNREFUSED")) {
            toast.error("처리 중 오류가 발생하였습니다.");
        } else {
            toast.error("처리 중 오류가 발생하였습니다.");
        }
    } else {
        if(error.response.data.code.indexOf("MODULE") > -1) {
            if(error.response.data.code == "MODULE_COMMON_ERROR_00002") {
                toast.error(error.response.data.validation[0]);
            } else {
                toast.error(utils.messages(error.response.data.code));
            }
        } else {
            toast.error("처리 중 오류가 발생하였습니다.");
            console.log("An error has been occured.", error);
        }
    }
    
    
    return Promise.reject(error);
}
*/

/*
export default {
    async get(...options) {
        try {
            const res = await service.get(...options);
            return res;
        } catch(e) {
            return handleError(e);
        }
    },

    async post(...options) {
        try {
            const res = await service.post(...options);
            return res;
        } catch(e) {
            return handleError(e);
        }
    },

    async put(...options) {
        try {
            const res = await service.put(...options);
            return res;
        } catch(e) {
            return handleError(e);
        }
    },

    async patch(...options) {
        try {
            const res = await service.patch(...options);
            return res;
        } catch(e) {
            return handleError(e);
        }
    },

    async delete(...options) {
        try {
            const res = await service.delete(...options);
            return res;
        } catch(e) {
            return handleError(e);
        }
    },
}
*/