import AuthService from '@/services/auth.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = () => {
    if(user) {
        let loggedIn = true;
        if(user.isPasswordReset || !user.isEnabled) {
            loggedIn = false;
        }

        return { status: { loggedIn }, user };
    } else {
        return { status: { loggedIn: false }, user: null };
    }
}

export const auth = {
    namespaced: true,
    state: initialState,
    actions: {
        login({ commit }, user) {
            return AuthService.login(user).then(
                user => {
                    commit('loginSuccess', user);
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure');
                    return Promise.reject(error);
                }
            );
        },
        logout({ commit }) {
            AuthService.logout();
            commit('logout');
        },
        logoutForce({ commit }) {
            commit('logout');
        },
        refreshToken({ commit }, accessToken) {
            commit('refreshToken', accessToken);
        }
    },
    mutations: {
        loginSuccess(state, user) {
            if(user.isPasswordReset || !user.isEnabled) {
                state.status.loggedIn = false;
            } else {
                state.status.loggedIn = true;
            }
            
            state.user = user;
        },
        loginFailure(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
        },
        refreshToken(state, accessToken) {
            state.status.loggedIn = true;
            state.user = { ...state.user, accessToken: accessToken };
        }
    },
    getters: {
        isLoggedIn: state => state.status.loggedIn,
        getUserName: state => state.user.getUserName
    }
}