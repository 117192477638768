export const loading = {
    state: {
        loading: false
    },
    actions: {
        startLoading: ({commit}) => {
            commit('startLoading');
        },
        endLoading: ({commit}) => {
            commit('endLoading');
        }
    },
    mutations: {
        startLoading(state){
            state.loading = true;
        },
        endLoading(state){
            state.loading = false;
        }
    },
    getters: {
        getLoading(state) {
            return state.loading;
        }
    }
}