import { createStore } from 'vuex'
import { auth } from "./auth.module";
import { loading } from './loading.module';
//import createPersistedState from "vuex-persistedstate";

const store = createStore({
    modules: {
        auth,
        loading
    },
    /*
    plugins: [
        createPersistedState({
            paths: ["userInfo"]
        })
    ]
    */
});

export default store;