<template>
  <div id="app" class="vl-parent">
    <router-view></router-view>
    <LoadingIndicator :loading="$store.getters.getLoading" />
  </div>
</template>

<script>
import appConfig from "@/app.config";
import LoadingIndicator from "./components/loadingIndicator.vue"
//import { jwtDecode } from 'jwt-decode';

export default {
  name: "App",
  created() {
    //메인 컴포넌트를 렌더링하면서 토큰체크
    /*
    let token = TokenService.getUser().getToken;
    
    if(token.access == null && token.refresh == null) { //access, refresh 모두 없으면 로그인 페이지로 이동
      this.$router.push({name: "login"}).catch(() => {});
    }

    setInterval(() => {
      const accessToken = this.$cookies.get("accessToken");
      let decoded = jwtDecode(accessToken);
      let now = new Date();
      let expiry = decoded.exp - Number(now.getTime().toString().substr(0, 10));

      console.log(expiry);
      if(expiry == 0) {
        this12.$router.push({ name: "login", query: { action: "expired" } });
      }
    }, 1000);
    */
  },
  components: {
    LoadingIndicator
  },
  computed: {
        getEnv() {
            if(this.env == "LOCAL") {
                return "[로컬] ";
            } else if(this.env == "DEV") {
                return "[개발] ";
            }
        }
    },
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
};
</script>
